import React from "react";
import Layout from "../components/layout";
import WeDoThatList from "../components/homepage/WeDoThatList";
import TrustUs from "../components/homepage/TrustUs";
import Services from "../components/homepage/Services";
import HomeVideoBack from "../Utils/HomeVideoBack";

import VideoDesk from "../videos/Inglesdesk.mp4";
import VideoMobile from "../videos/MobIng.mp4";

export default function index() {
  return (
    <>
      <Layout>
        <HomeVideoBack videoUrl={VideoDesk} videoMobile={VideoMobile} />
        <WeDoThatList />
        <TrustUs />
        <Services />
      </Layout>
    </>
  );
}

export const Head = () => (
  <>
    <title>Datastream</title>
    <meta name="description" content="Our partners are already providers for some of the largest accounts in Mexico, for the public and private sectors." />
    <meta name="keywords" content="Datastream, Datastream mx"/>
  </>
)
