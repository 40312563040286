import React from "react";
import useWindowSize from "../hooks/useWindowSize";

const VideoResponsive = {
  width: "100%",
  height: "80vh",
};

// const MainContainer = {
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center",
//   height: "100vh",
//   backgroundColor: "#232323",
// };

const VideoBanner = ({ videoUrl, videoMobile }) => {
  const screenSize = useWindowSize();

  return (
    <div>
      {screenSize < 720 ? (
        <video style={VideoResponsive} autoPlay playsInline loop muted>
          <source src={videoMobile} type="video/mp4" />
        </video>
      ) : (
        <video style={VideoResponsive} autoPlay playsInline loop muted>
          <source src={videoUrl} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default VideoBanner;
