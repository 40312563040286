import React from "react";
import styled from "styled-components";
import Whats from "../Assets/button_whatsapp.webp";
import Whats2 from "../Assets/icon_whats_black.svg";
// import useWindowSize from "../hooks/useWindowSize";
import "../css/typographyc.css";
import VideoBanner from "./VideoBanner";

const StyledHome = styled.div`
  img {
    width: 100%;
  }
  .desktop {
    display: none;
  }
  .dot {
    height: 52px;
    width: 52px;
    background-color: #0065ff;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-top: 100px;
    img {
      width: 35px;
      margin-top: 10px;
    }
  }
  .container {
    position: relative;
    background-color: black;
    iframe{
      border: none;
    }
  }

  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 82px;
    left: 16px;
    a {
      background-color: transparent;
      border: none;
      cursor: pointer;
      img {
        width: 48px;
      }
    }
  }
  .test {
    display: none;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    h1 {
      font-family: "Russo_one";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
    a {
      margin-top: 100px;
    }
  }
  @media (min-width: 800px) {
    .centered {
      h1 {
        font-size: 35px;
      }
    }
  }
  @media (min-width: 1200px) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
    .centered {
      h1 {
        width: 800px;
        font-size: 48px;
        font-weight: 400;
        line-height: 56px;
      }
    }
    .bottom-left {
      display: none;
    }
    .test {
      display: block;
      position: absolute;
      bottom: 92px;
      left: 16px;
      font-family: "Noto_sans_regular";
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 191px;
        border-radius: 45px;
        height: 48px;
        border: none;
        cursor: pointer;
        color: black;
        background-color: var(--green-button);
        font-family: "Noto_sans_regular";
        img {
          width: 24px;
        }
        p {
          margin-left: 12px;
        }
      }
    }
  }
`;
export default function HomeVideoBack(props) {
  // const screenSize = useWindowSize;
  const {videoUrl, videoMobile} = props;
  return (
    <StyledHome>
      <div class="container" >
        {/* <iframe
          width="100%"
          height="500px"
          src={screenSize < 1200 ? videoMobile : videoUrl}
          allow='autoplay; encrypted-media'
        /> */}

        <VideoBanner videoUrl={videoUrl} videoMobile={videoMobile} />

        <div class="bottom-left">
          <a href="https://api.whatsapp.com/send?phone=525627161515">
            <img src={Whats} alt="boton_whats" />
          </a>
        </div>
        <div className="test">
          <a href="https://api.whatsapp.com/send?phone=525627161515">
            <img src={Whats2} alt="boton_whats" />
            <p>Contáctanos</p>
          </a>
        </div>
      </div>
    </StyledHome>
  );
}
